import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [0,2,4,5];

export const dictionary = {
		"/": [~7],
		"/api/auth": [32],
		"/changelog": [33],
		"/(app)/(authorized)/collections": [~8,[2],[3]],
		"/(app)/(authorized)/collections/[collectionId]": [~9,[2],[3]],
		"/(email-tests)/emails/share": [~31,[6]],
		"/(app)/faqs": [~20,[2],[3]],
		"/(app)/help": [21,[2],[3]],
		"/(app)/locations/[country=country]": [~23,[2],[3]],
		"/(app)/locations/[slug]": [~24,[2],[3]],
		"/(app)/locations/[...rest]": [~22,[2],[3]],
		"/(app)/(authorized)/plan": [~10,[2],[3]],
		"/(app)/pricing": [~25,[2],[3]],
		"/(app)/(authorized)/profile": [11,[2],[3]],
		"/(app)/(authorized)/profile/billing": [~12,[2],[3]],
		"/(app)/(authorized)/profile/billing/invoices/[id]": [~13,[2],[3]],
		"/(app)/(authorized)/profile/subscriptions": [14,[2],[3]],
		"/(app)/(authorized)/projects": [~15,[2],[3]],
		"/(app)/(authorized)/projects/[projectId]": [16,[2,4],[3]],
		"/(app)/(authorized)/projects/[projectId]/budgets": [~17,[2,4],[3]],
		"/(app)/(authorized)/projects/[projectId]/budgets/[budgetId]": [~18,[2,4],[3]],
		"/(app)/(authorized)/projects/[projectId]/locations": [19,[2,4],[3]],
		"/(auth)/reset-password": [28,[5]],
		"/(auth)/signin": [29,[5]],
		"/(app)/signup": [26,[2],[3]],
		"/(auth)/signup/error": [30,[5]],
		"/(app)/signup/success": [~27,[2],[3]],
		"/widgets": [~34]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';