import type { HandleClientError, NavigationEvent } from "@sveltejs/kit";
import * as Sentry from "@sentry/sveltekit";
import { dev } from "$app/environment";

const clientHandleError = (({ error, event }: { error: Error; event: NavigationEvent }) => {
  // captureClientException(error, event);
  if (dev) {
    console.error("Client error", error);
  }
  return {
    message: error.message ?? "An unknown client error occurred",
  };
}) as HandleClientError;

export const handleError = Sentry.handleErrorWithSentry(clientHandleError);
